@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,500,600,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
    display: flex;
    width: 100%;
    align-items: stretch;
    height: 100vh;
  }
  
  
  body {
    /*font-family: 'Poppins', sans-serif;*/
    font-family: 'Nunito Sans', sans-serif;
    /*background: #fafafa;*/
    background-color: #f5f9fc;
  }
  
  
  a,
  a:hover,
  a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.2s;
  }
  
  /* ---------------------------------------------------
      SIDEBAR STYLE
  ----------------------------------------------------- */
  .sidebar {
    min-width: 250px;
    max-width: 250px;
    background: #354052;
    color: #adb5bd;
    margin-left: -250px;
    transition: all 0.5s;
    height: 100vh;
  }
  
  .sidebar.is-open {
    margin-left: 0;
    transition: .5s;
  }
  
  
  .sidebar-header {
    background: #313b4c;
    color: #adb5bd;
  }
  
  .sidebar-header h3{
    color: #fff;
    padding: 1em;
  }
  
  .sidebar ul p {
    color: #fff;
    padding: 10px;
  }
  
  .sidebar .collapse.show, 
  .sidebar .collapsing {
    background: #313b4c;
  }
  
  .sidebar .nav-item {
    /*color: #ced4da;*/
    color: #adb5bd;
    font-weight: 300;
  }
  
  .sidebar .nav-item.open {
    color: #e9ecef;
    background: #313b4c;
  }
  
  .sidebar .nav-item.active {
    color: #e9ecef;
    background: #2d3646 !important;
  }
  
  .sidebar .nav-item:hover {
    background: #313b4c;
    color: #fff;
    transition: .2s;
  }
  
  .sidebar .nav-item .svg-inline--fa {
    color: inherit;
  }
  
  .sidebar li a.dropdown-toggle::after {
    display: inline-flex;
    position: relative;
    left: 60%;
    top: 10%
  }
  
  .sidebar .sidebar-header > button {
    position: relative;
    float: right;
    margin: .5em;
    font-size: 2rem;
    cursor: pointer;
    display: none;
  }
  
  
  /* ---------------------------------------------------
      CONTENT STYLE
  ----------------------------------------------------- */
  .content {
    padding: 20px;
    margin-left: 0;
    height: 100vh;
    overflow: scroll;
  }

  .custom-scrollbar-js,
.custom-scrollbar-css {
  height: 200px;
}


/* Custom Scrollbar using CSS */
.custom-scrollbar-css {
  overflow-y: scroll;
}

/* scrollbar width */
.custom-scrollbar-css::-webkit-scrollbar {
  width: 5px;
}

/* scrollbar track */
.custom-scrollbar-css::-webkit-scrollbar-track {
  background: #eee;
}

/* scrollbar handle */
.custom-scrollbar-css::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: #00d2ff;
  background-image: linear-gradient(to top, #00d2ff 0%, #3a7bd5 100%);
}
  
  @media only screen and (max-width: 575.98px) {
    body {
      overflow:  hidden;
    }
    
    .content.is-open {
      /*margin-left: 100%;*/
      display: none;
      transition: all 0.5s;
    }
    
    .sidebar.is-open {
      min-width: 100%;
      max-width: 100%;
      margin-left: 0;
      transition: all 0.5s, height 0s;
      height: 100vh !important;
    }
    
    .sidebar.is-open > .sidebar-header button {
      display: block;
    }
    
    li a.dropdown-toggle::after {
      display: inline-block;
      position: relative;
      left: 68%;
    }
    
  }


  @media (min-width: 992px) { 
    #drop-user-menu{
      position:absolute !important; top:0; right:0
    }
  }



  
  
  
